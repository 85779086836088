<template>
  <div ref="preloader" class="preloader">
    <svg width="79" height="40" viewBox="0 0 79 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M71.4178 14.4019C72.021 10.4412 69.6624 7.42192 61.7814 7.1633C56.0991 6.97684 51.6715 8.77521 50.6882 13.632C50.088 16.6032 51.3397 18.7775 53.9577 19.9804C50.9748 21.0029 48.8635 23.0117 48.206 26.4461C47.1775 31.8111 51.4966 34.7973 58.4818 34.7973C65.4671 34.7973 69.9399 32.0517 70.6246 27.3212C71.08 24.1726 69.9429 21.4119 66.3086 20.0345C69.195 18.9339 71.0227 16.9911 71.4178 14.4019ZM59.1815 14.6033C59.3565 13.0396 60.1105 11.8156 61.3742 11.8156C62.5565 11.8156 63.0451 13.0666 62.8702 14.6304C62.6953 16.1942 61.8719 17.4482 60.7077 17.4482C59.6068 17.4482 59.0036 16.1671 59.1785 14.6033H59.1815ZM61.5552 25.8807C61.3501 27.8445 60.3729 29.4203 58.9945 29.4203C57.6886 29.4203 56.9708 27.8114 57.1758 25.8476C57.3809 23.8839 58.2767 22.3471 59.7757 22.3471C61.1812 22.3471 61.7603 23.9199 61.5552 25.8807Z"
        fill="#FAA61A"
      />
      <path
        d="M65.9951 2.95016L62.9247 0L59.426 2.78776L55.9666 1.10067L55.0195 6.06271C56.7658 5.54245 58.6238 5.25976 60.5541 5.25976C64.285 5.25976 67.7504 6.31231 70.6489 8.1197L71.5085 1.33524L65.9951 2.95016Z"
        fill="#FAA61A"
      />
      <path
        d="M22.6387 15.9867C23.4682 12.6877 21.4745 8.5166 12.8093 8.29106C7.48894 8.15272 3.40516 8.87447 3.40516 8.87447L0 33.0682C0 33.0682 3.98727 33.6726 8.50235 33.6726C14.3023 33.6726 20.5878 32.7825 22.3794 27.0566C23.0218 25.0056 22.696 22.7952 21.0734 21.3547C19.9574 20.3653 18.7389 20.0766 18.7389 20.0766C18.7389 20.0766 21.8727 19.0241 22.6357 15.9837L22.6387 15.9867ZM10.4417 27.5708C9.79625 27.5708 8.81602 27.5137 8.81602 27.5137L9.51575 22.5366C9.51575 22.5366 10.3814 22.4434 11.2319 22.4434C13.4517 22.4434 14.815 23.2644 14.8753 24.7229C14.8753 26.5002 13.3462 27.5678 10.4417 27.5678V27.5708ZM14.7396 16.937C13.8981 17.7761 12.381 17.8633 11.6149 17.8633C11.0027 17.8633 10.1823 17.8122 10.1823 17.8122L10.8368 13.1689C10.8368 13.1689 11.7054 13.1298 12.2513 13.1298C14.242 13.1298 15.2735 13.8846 15.4243 15.0815C15.5087 15.7522 15.18 16.504 14.7426 16.94L14.7396 16.937Z"
        fill="white"
      />
      <path
        d="M42.7078 8.97974C41.6462 12.5283 37.5564 14.8981 33.3821 14.9522L32.4351 21.6675C32.6733 21.6795 32.9508 21.6916 33.2313 21.6916H33.3761C41.7638 21.5743 49.1924 16.1972 50.233 8.97974H42.7078Z"
        fill="white"
      />
      <path d="M26.6773 8.97974L23.3083 32.8817H30.3871L33.7531 8.97974H26.6773Z" fill="white" />
      <path
        d="M44.3878 18.6903C42.364 20.1037 39.9662 21.1443 37.3542 21.6946C37.692 22.1036 38.1988 22.8193 38.44 23.4509C38.9709 24.8282 39.0402 25.6432 38.7386 27.9618L38.06 32.8637H45.7028C45.9561 31.1706 46.3844 27.6761 46.502 26.5604C46.6649 24.9996 46.7976 23.2073 45.8264 21.1322C45.4675 20.3684 44.7648 19.1474 44.3878 18.6933V18.6903Z"
        fill="white"
      />
      <path
        d="M71.8281 8.92256C74.5577 11.9539 76.2075 15.8814 76.2075 20.1729C76.2075 29.7661 67.9826 37.543 57.8365 37.543C53.5838 37.543 49.6749 36.1747 46.5593 33.8801C49.9283 37.6242 54.9471 40 60.551 40C70.6971 40 78.9219 32.2231 78.9219 22.6298C78.9219 17.0573 76.1411 12.0983 71.8251 8.91956L71.8281 8.92256Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<style src="~/assets/styles/components/atomic/preloader.scss" lang="scss" />
